import React, { useContext } from "react"

import { Cover } from "../../anti/cover/cover"
import { Breadcrumb } from "../../anti/breadcrumb/breadcrumb"

import { LangContext } from "../../../context/lang-context"

export const ContactUsCover = ({ data }) => {
  const { lang } = useContext(LangContext)
  const block = data
  return (
    <Cover
      breadcrumb={<Breadcrumb path={lang === "EN" ? "/contact" : "/kontak"} />}
      variant="basic"
      theme="dark"
      img={block.image && block.image.sourceUrl}
      imgHeight="h-400px h-md-500px w-100"
    />
  )
}
