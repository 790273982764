import React from "react"
import { Link } from "components/anti/link/link"
// import { Card } from "../../anti/card/card"
import { Container, Section } from "../../anti/grid/grid"
import { Image } from "components/anti/image/image"

export const ContactUsContent = ({ data }) => {
  const block = data
  return (
    <Section className="contact-us-content">
      <Container className="mw-xl">
        <div className="contact-us-box">
          <div className="row row-0">
            <div className="col-md-6 col-lg-6">
              <Link to="https://www.google.com/maps/place/Treasury+Office+Tower/@-6.2279982,106.8064651,15z/data=!4m5!3m4!1s0x0:0x1932ce0709d82af4!8m2!3d-6.2281273!4d106.8064459">
                <img
                  src={block?.image?.sourceUrl}
                  className="img-fluid img-map"
                  // imgHeight="h-150px ratio-lg-1-1"
                  alt="Map"
                />
              </Link>
            </div>
            <div className="col-md-6 col-lg-6 col-content">
              <img
                src={block?.imagetitle?.sourceUrl}
                className="img-title mb-2"
                alt="Map"
              />
              {/* <h1>{block?.title}</h1> */}
              <div dangerouslySetInnerHTML={{ __html: block?.address }} />
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to={`tel:${block.phoneNo}`} className="nav-link">
                    <i className="ais ai-phone-alt" />
                    {block?.phoneNo}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`tel:${block.faxNo}`} className="nav-link">
                    <i className="ais ai-fax" />
                    {block?.faxNo}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`mailto:${block.email}`} className="nav-link">
                    <i className="ais ai-envelope" />
                    {block?.email}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}
