import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "components/seo"

import { ContactUsCover } from "../components/shift/contact-us/cover"
import { ContactUsContent } from "../components/shift/contact-us/content"
import { ContactUsForm } from "../components/shift/contact-us/form"

//Load Context
import { LangContext } from "../context/lang-context"

const ContactUsPage = () => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(GET_CONTACT_US_DETAILS)
  const block =
    lang === "EN"
      ? data.wordPress.page.contactUsPage
      : data.wordPress.page.translation.contactUsPage ||
        data.wordPress.page.contactUsPage

  return (
    <Layout>
      <Seo title="Contact Us" />
      <ContactUsCover data={block.cover} />
      <ContactUsContent data={block.contact} />
      <ContactUsForm data={block.contactFrom} />
    </Layout>
  )
}

export default ContactUsPage

const GET_CONTACT_US_DETAILS = graphql`
  query ContactUs {
    wordPress {
      page(id: "contact-us", idType: URI) {
        contactUsPage {
          contact {
            address
            email
            faxNo
            phoneNo
            title
            imagetitle {
              sourceUrl
            }
            image {
              sourceUrl
            }
          }
          contactFrom {
            title
            destinationEmail
          }
          cover {
            image {
              sourceUrl
            }
          }
        }
        translation(language: ID) {
          contactUsPage {
            contact {
              address
              email
              faxNo
              phoneNo
              title
              imagetitle {
                sourceUrl
              }
              image {
                sourceUrl
              }
            }
            contactFrom {
              title
              destinationEmail
            }
            cover {
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
