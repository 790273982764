import React, { useContext, useState } from "react"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import ReCAPTCHA from "react-google-recaptcha"
import { gql, useMutation } from "@apollo/client"

// import { Button } from "../../anti/buttons/buttons"
import { Container, Section } from "../../anti/grid/grid"

//Load Context
import { LangContext } from "../../../context/lang-context"
import { Button } from "../../anti/buttons/buttons"

const SUBMIT_QUERY = gql`
  mutation MyMutation(
    $name: String
    $email: String
    $inquiry: String
    $institution: String
    $message: String
    $phoneNumber: String
    $title: String
  ) {
    submit2FormContactUs(
      input: {
        fullName: $name
        email: $email
        inquiryType: $inquiry
        institution: $institution
        message: $message
        phoneNumber: $phoneNumber
        title: $title
      }
    ) {
      status
      message
    }
  }
`

const button = (submitStatus, loading, lang) => {
  // const { lang } = useContext(LangContext)
  if (submitStatus === "success") {
    return (
      <>
        <button
          disabled
          className="btn btn-success btn-block mt-4"
          type="submit"
        >
          <i className="fas fa-check"></i>
        </button>
        {/* <div className="d-flex justify-content-center pt-3">
          <p>Your message has been delivered</p>
        </div> */}
      </>
    )
  } else if (submitStatus === "error") {
    return (
      <>
        <button
          disabled={loading}
          className="btn btn-primary btn-block mt-4"
          type="submit"
        >
          {lang === "EN" ? "Send" : "Kirim"}
        </button>
        <div className="d-flex justify-content-center pt-3">
          <p>
            {lang === "EN"
              ? "Sorry, something went wrong."
              : "Maaf, terjadi kesalahan."}
          </p>
        </div>
      </>
    )
  } else {
    return (
      <button
        disabled={loading}
        className="btn btn-primary btn-block mt-4"
        type="submit"
      >
        {loading ? (
          <i className="fa fa-spinner fa-spin"></i>
        ) : lang === "EN" ? (
          "Send"
        ) : (
          "Kirim"
        )}
      </button>
    )
  }
}

const FormContact = ({ touched, errors, values, submitStatus, loading }) => {
  const { lang } = useContext(LangContext)
  return (
    <Form>
      <div className="form-row">
        <div className="form-group col-4 col-md-2">
          <label for="title">{lang === "EN" ? "Title" : "Panggilan"}</label>
          <Field as="select" className="custom-select" id="title" name="title">
            <option value="mr">{lang === "EN" ? "Mr." : "Bapak"}</option>
            <option value="mrs">{lang === "EN" ? "Mrs." : "Nyonya"}</option>
            <option value="ms">{lang === "EN" ? "Ms." : "Nona"}</option>
          </Field>
        </div>
        <div className="form-group col-8 col-md-5">
          <label for="name">{lang === "EN" ? "Name" : "Nama"}</label>
          <Field
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder={
              lang === "EN"
                ? "Enter your full name"
                : "Masukkan nama lengkap Anda"
            }
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="form-group col-md-5">
          <label for="institution">
            {lang === "EN" ? "Institution" : "Institusi"}
          </label>
          <Field
            type="text"
            name="institution"
            className="form-control"
            id="institution"
            placeholder={
              lang === "EN"
                ? "Your institution / company name"
                : "Nama Institusi / Perusahaan Anda"
            }
          />
          {touched.institution && errors.institution && (
            <div className="invalid-value d-block">{errors.institution}</div>
          )}
        </div>
        <div className="form-group col-md-5">
          <label for="phone">
            {lang === "EN" ? "Phone Number" : "Nomor Telepon"}
          </label>
          <Field
            type="tel"
            className="form-control"
            name="phoneNumber"
            id="phone"
            placeholder="eg +62 812 1234 1234"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="form-group col-md-7">
          <label for="email">Email</label>
          <Field
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="eg info@shiftlegal.id"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="form-group col-12">
          <label for="natureOfInquiry">
            {lang === "EN" ? "Nature of Inquiry" : "Jenis Pertanyaan"}
          </label>
          <Field
            as="select"
            className="custom-select"
            id="inquiry"
            name="inquiry"
            placeholder="Please Choose one"
            selected={values.inquiry || "Request Proposal"}
          >
            <option value="Request Proposal">
              {lang === "EN" ? "Request Proposal" : "Dapatkan Penawaran"}
            </option>
            <option value="Join Us / Become A Shift Maker">
              {lang === "EN"
                ? "Join Us / Become A Shift Maker"
                : "Bergabung Bersama Kami / Menjadi Pembawa Perubahan"}
            </option>
            <option value="General Inquiry">
              {lang === "EN" ? "General Inquiry" : "Pertanyaan Umum"}
            </option>
          </Field>
        </div>
        <div className="form-group col-12">
          <label for="message">{lang === "EN" ? "Message" : "Pesan"}</label>
          <Field
            name="message"
            className="form-control"
            placeholder={
              lang === "EN" ? "Enter your message" : "Masukkan pesan Anda"
            }
            rows={4}
            as="textarea"
          />
          {touched.message && errors.message && (
            <div className="invalid-value d-block">{errors.message}</div>
          )}
        </div>
        <div className="form-group col-12">
          <Field name="recaptcha">
            {({ field, form }) => (
              <ReCAPTCHA
                sitekey="6LdnDMQaAAAAAFAHGkh2SwJ0dtJ8v5jSc8sW6VCq"
                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={value => {
                  form.setFieldValue("recaptcha", value)
                }}
              />
            )}
          </Field>
          {touched.recaptcha && errors.recaptcha && (
            <div className="invalid-value d-block">{errors.recaptcha}</div>
          )}
        </div>
        <div className="d-flex flex-row-reverse col-12">
          <div className="form-group">
            {/* <Button variant="primary" type="submit">
                {lang === "EN" ? "Send" : "Kirim"}
              </Button> */}
            {button(submitStatus, loading, lang)}
          </div>
        </div>
      </div>
    </Form>
  )
}

export const ContactUsForm = ({ data }) => {
  const block = data
  const { lang } = useContext(LangContext)
  const [formValues, setFormValues] = useState({
    title: "mr",
    name: "",
    institution: "",
    phoneNumber: "",
    email: "",
    message: "",
    inquiry: "Request Proposal",
  })
  const [submitSuccess, setSubmitSuccess] = useState()
  const [alertSuccess, setAlertSuccess] = useState()

  const [submitData, { data: result, loading }] = useMutation(SUBMIT_QUERY)

  const EnhancedContactForm = withFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          lang === "EN" ? "Invalid email address" : "Alamat email tidak berlaku"
        )
        .required(
          lang === "EN" ? "Email is required" : "Alamat email perlu dilengkapi"
        ),
      name: Yup.string().required(
        lang === "EN" ? "Name is required" : "Nama perlu dilengkapi"
      ),
      institution: Yup.string().required(
        lang === "EN"
          ? "Institution Name is required"
          : "Nama institusi perlu dilengkapi"
      ),
      phoneNumber: Yup.string()
        .required(
          lang === "EN"
            ? "Phone Number is required"
            : "Nomor telepon perlu dilengkapi"
        )
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
          lang === "EN"
            ? "Phone Number format doesn't match"
            : "Format nomor telepon tidak sesuai"
        ),
      message: Yup.string().required(
        lang === "EN" ? "Message is required" : "Pesan perlu dilengkapi"
      ),
      recaptcha: Yup.string().required(
        lang === "EN"
          ? "Please verify that you are not a robot"
          : "Mohon pastikan bahwa anda bukan robot"
      ),
    }),
    mapPropsToValues({
      title,
      email,
      name,
      institution,
      message,
      phoneNumber,
      inquiry,
      recaptcha,
    }) {
      return {
        title: title || formValues.title,
        name: name || formValues.name,
        institution: institution || formValues.institution,
        phoneNumber: phoneNumber || formValues.phoneNumber,
        email: email || formValues.email,
        message: message || formValues.message,
        inquiry: inquiry || formValues.inquiry,
        recaptcha: recaptcha || "",
      }
    },
    handleSubmit(values) {
      setFormValues(values)

      submitData({
        variables: { ...values, phoneNumber: `${values.phoneNumber}` },
      })
        .then(res => {
          setSubmitSuccess("success")
          setAlertSuccess(true)
          setFormValues({
            name: "",
            institution: "",
            phoneNumber: "",
            email: "",
            message: "",
          })
        })
        .then(res => {
          setTimeout(() => {
            setAlertSuccess(false)
          }, 5000)
        })
        .catch(err => console.log("err", err))
    },
  })(FormContact)

  const closeBtnClick = () => {
    setAlertSuccess(false)
  }

  return (
    <Section className="contact-us-form-area pt-0">
      <div
        className={`w-100 d-flex justify-content-center ${
          alertSuccess ? "alert-show" : "alert-hide"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center alert alert-success alert-contact">
          <p className="mb-0">
            {lang === "EN"
              ? "Thank you. Your message was sent successfully."
              : "Terima kasih. Pesan Anda sudah berhasil terkirim."}
          </p>
          <Button variant="link-alt" onClick={closeBtnClick}>
            <span className="close-btn">&times;</span>
          </Button>
        </div>
      </div>
      <Container className="mw-xl">
        <div className="w-md-600px pb-3 pb-md-4 pb-lg-5">
          <h2 className="h1">{block?.title}</h2>
        </div>
        <div className="contact-us-form">
          <EnhancedContactForm submitStatus={submitSuccess} loading={loading} />
        </div>
      </Container>
    </Section>
  )
}
